<template>
  <div class="panel-container">
    <div class="card-title">
      <div>
        <div class="subtitle2 main">{{ card.service_name }}</div>
        <div class="flex-align" style="margin-top:8px">
          <div class="flex-align">
            <div :class="`box-type-${card.service_type}`">
              <img :src="typeIcon">
            </div>
            <div :class="`color-type-${card.service_type} subtitle5`" style="margin:0 8px">{{ typeName }}</div>
          </div>
          <div class="status" :class="`${status.class}`">{{ status.name }}</div>
          <div class="body5-medium primary" style="margin-left:8px"
            v-if="status.class==='status-ready' && card.service_type === 0">
            *개설이 완료되면 메일로 알려드립니다.(예상 소요시간 15~20분)
          </div>
        </div>
      </div>
      <div class="flex-align">
        <div class="body2-medium sub2" style="margin-left:8px">서비스 시작일 <span class="body2 sub3" style="margin:0 20px 0 12px">{{ datesFormat(card.service_date, 'date_3') }}</span></div>
        <button v-if="!detail" class="button is-gray" style="width:120px"
                @click="routerPush(`my_service_detail?id=${card.id}`)">상세보기</button>
        <button v-if="!detail && showModify(card)" class="button is-gray margin-left-12"
                style="width:120px" @click="modifyService(card)">수정하기</button>
      </div>
    </div>
    <div v-for="(item,idx) in list" :key="'site-'+idx">
      <div class="h8 flex-align" style="margin:32px 0 8px 0">{{ item.name }} 도메인
        <tool-tip v-if="idx===0 && status.class !== 'status-terminate' && item.domain.indexOf('launchpack.')>-1"
                  icon="fi_info"
                  style="margin-left:4px"
                  :iconSize="20"
                  text="추가 도메인 등록을 원하시면 문의를 남겨주세요"/></div>
      <div v-if="status.class==='status-run'"  class="flex">
        <a :href="item.domain" target="_blank">
          <div class="flex">
            <div class="body4 main domain">{{ item.domain }} <img class="icon-link" src="/static/img/ic_arrow_45.svg"></div>
          </div>
        </a>
      </div>
      <div v-else class="body4" :class="`${status.class==='status-ready' ? 'sub3' : 'sub4'}`">{{ item.domain }}</div>
      <div class="body4 sub4" v-if="status.class === 'status-ready'">*개설 완료 후 도메인 접속이 가능합니다.</div>
    </div>
  </div>
</template>

<script>
  import ToolTip from "./ToolTip";
  export default {
    name: "CardMyServicePc",
    components: {ToolTip},
    props: {
      card: {
        type: Object
      },
      detail: {
        type: Boolean,
        default: false
      }
    },
    created() {
      this.list = [
        {
          name: '사이트',
          domain: this.card.domain
        },
        {
          name: '어드민',
          domain: this.card.domain + '/office/admin'
        },
      ];
    },
    data() {
      return {
        list: []
      }
    },
    methods: {
      showModify(card) {
        return [10,99,100].indexOf(card.status) === -1
      },
      modifyService(card) {
        if(card.status===9) {
          window.open(card.domain+'/office/admin/service/info','_blank');
        } else {
          this.toast('개설 완료 후 수정이 가능합니다.')
        }
      }
    },
    computed: {
      status() {
        let statusObj = {
          0: '이용예정',
          5: '개설 중',
          6: '디자인 수정 진행 중',
          9: '이용 중',
          10: '관리자 정지',
          99: '전체정지',
          100: '이용종료'
        };
        let status = {
          name: statusObj[this.card.status],
          class: 'status-ready'
        };
        // 이용종료, 전체정지, 관리자 정지, 이용예정
        if([0,10,99,100].indexOf(this.card.status) > -1) {
          status.class = 'status-terminate';
        }
        // 개설 중
        else if([1,5].indexOf(this.card.status) > -1) {
          status.class = 'status-ready';
        }
        else if(this.card.status === 9) {
          status.class = 'status-run';
        }
        return status;
      },
      typeIcon() {
        let tp = {
          0: 'u:bolt',
          1: 'u:store-alt',
          2: 'u:file-edit-alt',
          3: 'u:cog'
        };
        return `/static/icon/${tp[this.card.service_type].replace(':','_')}.svg`;
      },
      typeName() {
        let tp = {
          0: '퀵빌더',
          1: '디자인 마켓',
          2: '주문제작',
          3: 'Solution API'
        }
        return tp[this.card.service_type];
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .panel-container
    margin-bottom 24px
  .panel-container:last-child
    margin-bottom 0

  .card-title
    display flex
    justify-content space-between
    border-bottom 1px solid $gray2
    padding-bottom 24px

  .status
    padding 4px 8px
    border-radius 4px
    font-size 13px
    font-weight 500
  // 개설중(결제완료), 디자인 수정 중
  .status-ready
    background-color $gray3
    color $primary
  // 이용중
  .status-run
    background-color $primary-light2
    color $primary
  // 이용종료, 전체정지, 관리자 정지, 이용예정
  .status-terminate
    background-color $gray3
    color $sub3

  .domain:hover
    color $primary
    .icon-link
      filter invert(54%) sepia(46%) saturate(5884%) hue-rotate(360deg) brightness(100%) contrast(110%)

  .icon-link
    margin-left 8px

  .box-type-0
  .box-type-1
  .box-type-2
  .box-type-3
    width 28px
    height 28px
    border-radius 8px
    display flex
    justify-content center
    align-items center
    img
      filter invert(100%) sepia(100%) saturate(100%) hue-rotate(245deg) brightness(1000%) contrast(1000%)
      width 14px

  .box-type-0
    background linear-gradient(322.3deg, #FF6600 22.51%, #F43B00 100%)
  .box-type-1
    background-color #7B61FF
  .box-type-2
    background linear-gradient(148.67deg, #00D3BA 30.79%, #17FF90 96.89%)
  .box-type-3
    background linear-gradient(132.62deg, #242428 34.49%, #4F4F4F 88.8%)

  .color-type-0
    color $primary
  .color-type-1
    color #7B61FF
  .color-type-2
    color #00D3BA
  .color-type-3
    color $main
</style>
